import React from 'react'
import PropTypes from 'prop-types'
import SocialMediaButton from "../SocialMediaButton"
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'

const FacebookButton = props => (
  <SocialMediaButton
    href={props.href}
    icon={faFacebookF}
    tooltipText="Share to Facebook!"
  />
)

FacebookButton.propTypes = {
  href: PropTypes.string.isRequired,
}

export default FacebookButton
