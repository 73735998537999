/**
 * use the Clipboard API to write to the user's clipboard https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
 * @param  {Any? Not sure tbh} newClip  what you want to be copied to the clipboard
 * @param  {Function} successCallback   the callback to run if successful
 * @param  {Function} errCallback       the callback to run if error
 * @return {Promise}                    the writeText Promise
 */
export default function writeToClipboard(newClip, successCallback=()=>{}, errCallback=()=>{}) {
  return navigator.clipboard.writeText(newClip).then(successCallback,errCallback);
}
