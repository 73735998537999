import React, { Component } from 'react';
import { Link } from 'gatsby'
import Footer from 'components/footer/Footer';

import ROUTES from "utils/routes";
import Terms from 'components/termsmodal/Terms';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import './DataDownloadHomepage.scss';

export default class DataDownload extends Component {
  clickTerms = () => {
    return(
      <Terms message="Terms of Service" hideForm={true}/>
    )
  }

  render() {
    return (
      <div className="dataDownload">
        <Container className="homepage">
          <Row>
            <Col lg={12}>
              <div className="title"><div className="mainTitle">Data Download</div></div>
            </Col>

            <Col lg={12}>
              <div className="title"><div>How to Cite</div></div>
              <p>Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.”  Working paper available from <a href="http://web.mit.edu/insong/www/pdf/lobbyview.pdf" target="_blank" rel="noopener noreferrer">http://web.mit.edu/insong/www/pdf/lobbyview.pdf</a></p>
              <br/>
            </Col>
          </Row>

          <Row id="cardRow">
            <Col onClick={() => this.clickTerms()} className="card" md={12} lg={3}>
              <div className="title"><div>Terms of Service</div></div>
              <p>View Lobbyview's Terms of Service and Privacy Policies for your benefit.</p>
              <Terms message="Terms of Service" hideForm={true}/>
            </Col>

            <Col className="card" md={12} lg={{ span: 3, offset: 1 }}>
                <div className="title"><div>Download Dataset</div></div>
                <p>Explore premade Datasets provided by the Lobbyview Team in CSV, DTA, and JSON formats.</p>
                <Link to={ROUTES.DATASETS}><Button className="roundTealButton">Explore Datasets</Button></Link>
            </Col>

            <Col className="card" md={12} lg={{ span: 3, offset: 1 }}>
              <div className="title"><div>LobbyView's API</div></div>
              <p>Use LobbyView's API to interact with large amounts of data programmatically.</p>
              <Link to={ROUTES.API}><Button className="roundTealButton">Get Started</Button></Link>
            </Col>
          </Row>
        </Container>

        <Footer />
      </div>
    )
  }
}
