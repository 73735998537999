import React from 'react'
import PropTypes from 'prop-types'
import SocialMediaButton from "../SocialMediaButton"
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons'

import "./reddit.scss"

const RedditButton = props => (
  <SocialMediaButton
    className="reddit"
    href={props.href}
    icon={faRedditAlien}
    tooltipText="Share to Reddit!"
  />
)

RedditButton.propTypes = {
  href: PropTypes.string.isRequired,
}

export default RedditButton
