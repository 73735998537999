/**
 * given a mailto string and parameters, create a href string for an anchor tag
 * @param  {String} [mailto="email@example.com"] email address
 * @param  {Object} [params={}]                  an object of params, meaningful params include "cc", "bcc", "subject", "body"
 * @return {String}                              href string
 */
export default function getEmailShareLink(mailto="email@example.com", params={}) {
  const keys = Object.keys(params)
  return "mailto:" + mailto + (
    keys.length>0
      ? "?"+keys.map(key => key+"="+params[key]).join("&")
      : ""
  )
}
