import React, { Component } from 'react';
import "./Footer.scss";
import { StaticImage } from 'gatsby-plugin-image';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SocialMedia from 'components/socialMedia/SocialMedia';

export default class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <Container>
          <Row>
            <Col md={9}>
              <div>Supported by</div>
              <br/>
              <div>
                <span className="sponsor">
                  <StaticImage src="./mit.jpg" alt="Massachusetts Institute of Technology"/>

                  Massachusetts Institute of Technology
                </span>
                <span className="sponsor">
                  <StaticImage src="./nsf.png" alt="National Science Foundation Logo" className="logo-right"/>

                  National Science Foundation
                </span>
              </div>
              <br/>
              <div className="sponsor">
                <StaticImage src="./rsf.jpg" alt="Russel Sage Logo"/>

                <p>Russel Sage Foundation</p>
              </div>
            </Col>
            <Col md={3} id="right">
              <a id="email" href="mailto:lobbydata@gmail.com">lobbydata@gmail.com</a>
              <SocialMedia />
            </Col>
          </Row>
        </Container>

      </div>
    )
  }
}
