import React from 'react'
import PropTypes from 'prop-types'
import SocialMediaButton from "../SocialMediaButton"
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import "./twitter.scss"

const TwitterButton = props => (
  <SocialMediaButton
    className="twitter"
    href={props.href}
    icon={faTwitter}
    tooltipText="Share to Twitter!"
  />
)

TwitterButton.propTypes = {
  href: PropTypes.string.isRequired,
}

export default TwitterButton
